import React, { useEffect, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { FiLogOut, FiArrowRight } from "react-icons/fi";
import { toast } from "react-toastify";

import api from "../../services/api";

import "./styles.css";

import logoImg from "../../assets/virtex3.png";

export default function Profile() {
  const [invoices, setInvoices] = useState([]);

  const history = useHistory();
  toast.configure({
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });

  const p_name = localStorage.getItem("VP_NAME");
  const p_token = localStorage.getItem("VP_TOKEN");

  useEffect(() => {
    if (!p_token) {
      history.push("/profile");
    }
  }, [p_token, history]);

  const handleLogout = useCallback(() => {
    localStorage.clear();
    history.push("/");
  }, [history]);

  function statusSolicitation(status) {
    switch (status) {
      case "solicited":
        return <span style={{ color: "#000" }}>Solicitado</span>;
      case "inanalysis":
        return (
          <span style={{ color: "rgba(248, 148, 6, 0.9)" }}>
            Solicitação Em Análise
          </span>
        );
      case "approved":
        return (
          <span style={{ color: "rgba(63, 195, 128, 0.9)" }}>
            Solicitação Aprovada
          </span>
        );
      case "rejected":
        return (
          <span style={{ color: "rgba(242, 38, 19, 0.9)" }}>
            Solicitação Recusada
          </span>
        );
      default:
        return <span style={{ color: "#000" }}>Não solicitado</span>;
    }
  }

  useEffect(() => {
    api
      .get("client/informations", {
        headers: {
          Authorization: `Bearer ${p_token}`,
        },
      })
      .then((response) => {
        if (!response.data) {
          alert(
            "Não foi possível recuperar suas assinaturas. Tente novamente em alguns minutos."
          );
        } else {
          setInvoices(response.data.data);
        }
      });
  }, [p_token]);

  return (
    <div className="dashboard-profile">
      <div className="profile-container fadeInDown">
        <header>
          <section>
            <div className="topo middle">
              <span className="virtex">Olá, </span>
              <span>
                bem vindo de volta
                <strong> {p_name}</strong>
              </span>
            </div>
          </section>
          <div className="menu">
            <div className="menu-container">
              <div className="middle">
                <div className="menu-miolo">
                  <div className="logo">
                    <img title="Virtex" alt="Virtex" src={logoImg}></img>
                  </div>
                  <div className="register">
                    <button
                      className="button"
                      onClick={handleLogout}
                      type="button"
                    >
                      <FiLogOut
                        size={18}
                        style={{
                          verticalAlign: "middle",
                        }}
                      />
                      <span style={{ paddingLeft: 5 }}>Sair</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="content">
          <div className="middle">
            <div className="content-4">
              <div className="components">
                <main>
                  <section className="title">
                    <h1>Minhas assinaturas</h1>
                  </section>
                  <section className="conteudo">
                    {invoices.length > 0 ? (
                      invoices.map((ass, i) => (
                        <div className="box" key={i}>
                          {/* <span className="box-title">
                          Contrato: {ass.contracNumber}
                        </span> */}
                          <div className="box-status">
                            <strong>Status:</strong>{" "}
                            {statusSolicitation(ass.status)}
                          </div>
                          <div className="box-message">
                            <strong>Mensagem:</strong>{" "}
                            {!ass.message
                              ? "Todas informações enviadas são sigilosas e serão analisadas exclusivamente por nossa equipe de colaboradores."
                              : ass.message}
                          </div>
                          <hr />
                          <div className="box-message">
                            <strong>Número do Contrato:</strong>{" "}
                            {ass.contractNumber}
                          </div>
                          <div className="box-message">
                            <strong>Plano:</strong> {ass.link}
                          </div>
                          <div className="box-message">
                            <strong>Endereço:</strong> {ass.address} /{" "}
                            {ass.city}
                          </div>
                          {ass.status === "nosolicited" ? (
                            <section className="action">
                              <ul>
                                <li>
                                  <div className="conteudo">
                                    <Link
                                      className="button voltarg"
                                      to={{
                                        pathname: `/solicitation`,
                                        state: {
                                          name: ass.name,
                                          contrato: ass.contractNumber,
                                        },
                                      }}
                                    >
                                      <FiArrowRight
                                        size={18}
                                        style={{
                                          verticalAlign: "middle",
                                        }}
                                      />
                                      <span style={{ paddingLeft: 5 }}>
                                        SOLICITAR BENEFÍCIO
                                      </span>
                                    </Link>
                                  </div>
                                </li>
                              </ul>
                            </section>
                          ) : null}
                        </div>
                      ))
                    ) : (
                      <div className="box">
                        <span className="box-title">
                          Não há registro de asssinaturas em seu cadastro.
                        </span>
                      </div>
                    )}
                  </section>
                </main>
              </div>
            </div>
            <div className="footer-profile">
              Copyright &copy; 2020 <strong>Virtex</strong>. Todos direitos
              reservados.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
