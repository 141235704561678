import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FiLogOut, FiSend } from "react-icons/fi";
import MaskedInput from "react-text-mask";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";

import api from "../../services/api";

import "./styles.css";

import logoImg from "../../assets/virtex3.png";

export default function Solicitation() {
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [anexos, setAnexos] = useState([]);
  const [justificativa, setJustificativa] = useState("");
  const [contrato, setContrato] = useState("");
  const [name, setName] = useState("");
  const [fileQuant, setFileQuant] = useState(0);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  toast.configure({
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const p_name = localStorage.getItem("VP_NAME");
  const p_token = localStorage.getItem("VP_TOKEN");

  useEffect(() => {
    if (location.state === undefined) {
      history.push("/profile");
    } else {
      setName(location.state.name);
      setContrato(location.state.contrato);
    }
  }, [contrato, location, history]);

  const handleLogout = useCallback(() => {
    localStorage.clear();
    history.push("/");
  }, [history]);

  async function handleSolicitation(e) {
    e.preventDefault();

    setLoading(true);

    if (fileQuant > 5) {
      await sleep(3000);
      setLoading(false);
      toast.warning(
        "Limite máximo de arquivos a serem enviados excedido. O máximo permitido é 5 arquivos."
      );
      return;
    }

    try {
      const dados = new FormData();

      dados.append("contract", contrato);
      dados.append("name", name);
      dados.append("email", email);
      dados.append("phoneNumber", whatsapp);
      dados.append("description", justificativa);

      for (let i = 0; i < anexos.length; i++) {
        dados.append("files", anexos[i]);
      }
      // Array.from(anexos).forEach((f) => {
      //   dados.append("files", f);
      // });

      const response = await api.post("client/solicitation", dados, {
        headers: {
          Authorization: `Bearer ${p_token}`,
        },
      });

      if (response.data.success) {
        await sleep(3000);
        setLoading(false);

        toast.success("Solicitação registrada com sucesso.");

        history.push("/profile");
      } else {
        await sleep(3000);
        setLoading(false);
        toast.error(
          "Não foi possível salvar os dados. Por favor verique e tente novamente."
        );
      }
    } catch (error) {
      await sleep(3000);
      setLoading(false);
      toast.warning(
        "Não foi possível salvar os dados. Por favor verique e tente novamente."
      );
    }
  }

  return (
    <div className="dashboard-solicitation">
      <div className="solicitation-container fadeInDown">
        <header>
          <section>
            <div className="topo middle">
              <span className="virtex">Olá, </span>
              <span>
                bem vindo de volta
                <strong> {p_name}</strong>
              </span>
            </div>
          </section>
          <div className="menu">
            <div className="menu-container">
              <div className="middle">
                <div className="menu-miolo">
                  <div className="logo">
                    <img title="Virtex" alt="Virtex" src={logoImg}></img>
                  </div>
                  {/* <nav>
                    <ul>
                      <li>
                        <a href="/profile">VISUALIZAR ASSINATURAS</a>
                      </li>
                    </ul>
                  </nav> */}
                  <div className="register">
                    <button
                      className="button"
                      onClick={handleLogout}
                      type="button"
                    >
                      <FiLogOut
                        size={18}
                        style={{
                          verticalAlign: "middle",
                        }}
                      />
                      <span style={{ paddingLeft: 5 }}>Sair</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="content">
          <div className="middle">
            <div className="content-4">
              <div className="components">
                <main>
                  <section className="title">
                    <h1>Solicitar benefício</h1>
                  </section>
                  <section className="conteudo">
                    <div className="box">
                      <div className="box-conteudo">
                        <p>Dicas:</p>
                        <ul>
                          <li>Informe seu melhor email</li>
                          <li>
                            Anexos são permitidos apenas os formatos .jpeg .jpg
                            .png e .pdf e no máximo 5 arquivos
                          </li>
                          <li>
                            Anexe arquivos de preferência que ajudem a comprovar
                            que você está sendo afetado pela pandemia
                          </li>
                        </ul>
                        <p>
                          <strong>Atenção: </strong>
                          Esta é uma iniciativa da VirteX em solidariedade com
                          esta situação gerada pela pandemia, não possuindo
                          nenhum apoio ou vínculo com Governo Federal, Estadual
                          ou Municipal.
                        </p>
                      </div>

                      <form onSubmit={handleSolicitation}>
                        <div className="box-form-group">
                          <label>Email:</label>
                          <input
                            required
                            type="email"
                            name="email"
                            className="form-elemento"
                            autoComplete="off"
                            placeholder="Digite seu Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          ></input>
                        </div>
                        <div className="box-form-group">
                          <label>Whatsapp:</label>
                          <MaskedInput
                            required
                            mask={[
                              "(",
                              /[0-9]/,
                              /[0-9]/,
                              ")",
                              " ",
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              "-",
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                            ]}
                            guide={false}
                            placeholder="(xx) xxxxx-xxxx"
                            value={whatsapp}
                            className="form-elemento"
                            onChange={(e) => setWhatsapp(e.target.value)}
                          />
                        </div>
                        <div className="box-form-group">
                          <label>Anexos:</label>
                          <input
                            required
                            multiple
                            accept="image/*,.pdf"
                            type="file"
                            name="anexos"
                            className="form-elemento"
                            onChange={(e) => {
                              setAnexos(e.target.files);
                              setFileQuant(e.target.files.length);
                            }}
                            autoComplete="off"
                          ></input>
                        </div>
                        <div
                          className="box-form-group"
                          style={{ width: "100%" }}
                        >
                          <label>Justificativa:</label>
                          <textarea
                            required
                            type="text"
                            name="justificativa"
                            value={justificativa}
                            className="form-elemento"
                            onChange={(e) => setJustificativa(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                        <div className="box-form-group">
                          {loading ? (
                            <button
                              className="button fadeIn third"
                              disabled
                              style={{ marginTop: 15 }}
                            >
                              <HashLoader
                                size={36}
                                color={"#fff"}
                                css={`
                                  display: block;
                                  margin: 0 auto;
                                `}
                              />
                            </button>
                          ) : (
                            <button
                              className="button fadeIn third"
                              type="submit"
                              style={{ marginTop: 15 }}
                            >
                              <FiSend
                                size={18}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              />
                              <span style={{ paddingLeft: 5 }}>Enviar</span>
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </section>
                </main>
              </div>
            </div>
            <div className="footer-solicitation">
              Copyright &copy; 2020 <strong>VirteX</strong>. Todos direitos
              reservados.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
