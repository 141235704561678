import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { FiHome, FiLogIn } from "react-icons/fi";
import MaskedInput from "react-text-mask";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";
import { cpf, cnpj } from "cpf-cnpj-validator";

import api from "../../services/api";

import "./styles.css";

import logoImg from "../../assets/virtex3.png";

export default function Login() {
  const [cpfcnpj, setCpfCnpj] = useState("");
  const [datanasc, setDatanasc] = useState("");
  const [cidade, setCidade] = useState("");
  const [loading, setLoading] = useState(false);

  toast.configure({
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });

  const history = useHistory();

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  async function handleLogin(e) {
    e.preventDefault();

    setLoading(true);

    try {
      if (!cpfcnpj || !datanasc || !cidade) {
        await sleep(3000);
        setLoading(false);
        toast.warning("Por favor preencha os dados e tente novamente.");
        setDatanasc("");
        setCpfCnpj("");
        setCidade("");
        return;
      }

      if (cnpj.isValid(cpfcnpj) || cpf.isValid(cpfcnpj)) {
        const response = await api.post("authenticate", {
          cpfCnpj: cpfcnpj,
          dateBirth: datanasc,
          city: cidade,
        });

        if (response.data.success) {
          await sleep(3000);
          setLoading(false);

          toast.success("Dados obtidos com sucesso");
          localStorage.setItem("VP_NAME", response.data.data.user);
          localStorage.setItem("VP_TOKEN", response.data.data.token);

          history.push("/profile");
        } else {
          await sleep(3000);
          setLoading(false);
          toast.error(
            "Não foi possível obter seus dados. por favor tente novamente."
          );
        }
      } else {
        await sleep(3000);
        setLoading(false);
        toast.warning("CPF ou CNPJ inválido. Por favor tente novamente.");
        setDatanasc("");
        setCpfCnpj("");
        return;
      }
    } catch (error) {
      await sleep(3000);
      setLoading(false);
      toast.error(
        "Não foi possível obter seus dados. por favor tente novamente."
      );
      setDatanasc("");
      setCpfCnpj("");
      return;
    }
  }

  function formatCnpjCpf(value) {
    let cnpjCpf = value.replace(/\D/g, "");

    let tamanho = cnpjCpf.length;

    switch (tamanho) {
      case 11:
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      case 14:
        return cnpjCpf.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          "$1.$2.$3/$4-$5"
        );

      default:
        return value;
    }
  }

  function isInputNumber(event) {
    let char = String.fromCharCode(event.which);
    if (!/[0-9]/.test(char)) {
      event.preventDefault();
    }
  }

  return (
    <div className="dashboard-login">
      <div className="login-container fadeInDown">
        <header>
          {/* <section>
            <div className="topo middle">
              <span className="virtex">Virtex. </span>
              <span>
                Nós estamos aqui para não deixar você desconectado do mundo.
                <strong> #FiqueEmCasa</strong>
              </span>
            </div>
          </section> */}
          <div className="menu">
            <div className="menu-container">
              <div className="middle">
                <div className="menu-miolo">
                  <div className="logo">
                    <img title="Virtex" alt="Virtex" src={logoImg}></img>
                  </div>
                  {/* <nav>
                    <ul>
                      <li>
                        <a href="/profile">VISUALIZAR ASSINATURAS</a>
                      </li>
                    </ul>
                  </nav> */}
                  <div className="register">
                    <Link className="button voltarg" to="/">
                      <FiHome
                        size={18}
                        style={{
                          verticalAlign: "middle",
                        }}
                      />
                      <span style={{ paddingLeft: 5 }}> Página Inicial</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="wrapper fadeInDown">
          <div id="formContent">
            <form onSubmit={handleLogin} className="fadeIn second">
              <h1>Faça seu login:</h1>

              <div className="box-form-group">
                <label>CPF/CNPJ:</label>
                <input
                  type="text"
                  value={cpfcnpj}
                  maxLength="18"
                  className="form-elemento"
                  placeholder="Digite seu CPF ou CNPJ"
                  onChange={(e) => setCpfCnpj(formatCnpjCpf(e.target.value))}
                  onKeyPress={(e) => isInputNumber(e)}
                />
              </div>

              <div className="box-form-group">
                <label>Data de nascimento/abertura:</label>
                <MaskedInput
                  required
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    "/",
                    /[0-9]/,
                    /[0-9]/,
                    "/",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                  guide={false}
                  placeholder="xx/xx/xxxx"
                  value={datanasc}
                  className="form-elemento"
                  onChange={(e) => setDatanasc(e.target.value)}
                />
              </div>

              <div className="box-form-group">
                <label>Cidade:</label>
                <select
                  required
                  name="cidade"
                  className="form-elemento"
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                >
                  <option value="">Local da assinatura</option>
                  <option value="Barão de Grajaú">Barão de Grajaú</option>
                  <option value="Bocaína">Bocaína</option>
                  <option value="Bom Jesus">Bom Jesus</option>
                  <option value="Canto do Buriti">Canto do Buriti</option>
                  <option value="Dom Expedito Lopes">Dom Expedito Lopes</option>
                  <option value="Floriano">Floriano</option>
                  <option value="Ipiranga">Ipiranga</option>
                  <option value="Jaicós">Jaicós</option>
                  <option value="Oeiras">Oeiras</option>
                  <option value="Picos">Picos</option>
                  <option value="Pio IX">Pio IX</option>
                  <option value="Santana">Santana</option>
                  <option value="São Julião">São Julião</option>
                </select>
              </div>

              {loading ? (
                <button
                  className="button fadeIn third"
                  disabled
                  style={{ marginTop: 15 }}
                >
                  <HashLoader
                    size={36}
                    color={"#fff"}
                    css={`
                      display: block;
                      margin: 0 auto;
                    `}
                  />
                </button>
              ) : (
                <button
                  className="button fadeIn third"
                  type="submit"
                  style={{ marginTop: 15 }}
                >
                  <FiLogIn
                    size={18}
                    style={{
                      verticalAlign: "middle",
                    }}
                  />
                  <span style={{ paddingLeft: 5 }}>Entrar</span>
                </button>
              )}
            </form>
          </div>
        </div>

        <div className="footer-login">
          Copyright &copy; 2020 <strong>Virtex</strong>. Todos direitos
          reservados. <br /> CNPJ 05.439.562/0001-50
        </div>
      </div>
    </div>
  );
}
