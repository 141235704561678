import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { FiLogOut, FiFile, FiUserCheck, FiUserX } from "react-icons/fi";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import PacmanLoader from "react-spinners/PacmanLoader";

import api from "../../services/api";

import "./styles.css";

import logoImg from "../../assets/virtex3.png";

export default function AdminDashboard() {
  const [solicitations, setSolicitations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);

  const history = useHistory();

  toast.configure({
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });

  const p_name = localStorage.getItem("VP_NAME");
  const p_token = localStorage.getItem("VP_TOKEN");

  useEffect(() => {
    if (!p_token) {
      history.push("/profile");
    }
  }, [p_token, history]);

  const handleLogout = useCallback(() => {
    localStorage.clear();
    history.push("/");
  }, [history]);

  const fetchSolicitatios = useCallback(
    async (page) => {
      setLoading(true);

      try {
        const response = await api.get(
          `admin/solicitations/list/all/${perPage}/${page}`,
          {
            headers: {
              Authorization: `Bearer ${p_token}`,
            },
          }
        );

        setSolicitations(response.data.data.solicitations);
        setTotalRows(response.data.data.totalAmount);
        setLoading(false);
      } catch (error) {
        toast.error("Acesso não autorizado.");
        handleLogout();
      }
    },
    [handleLogout, p_token, perPage]
  );

  const handlePageChange = (page) => {
    fetchSolicitatios(page);
    setPagina(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await api.get(
      `admin/solicitations/list/all/${perPage}/${page}`,
      {
        headers: {
          Authorization: `Bearer ${p_token}`,
        },
      }
    );

    setSolicitations(response.data.data.solicitations);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(() => {
    (async () => {
      const fetchSolicitatio = async (page) => {
        setLoading(true);
        await sleep(3000);
        try {
          const response = await api.get(
            `admin/solicitations/list/all/${perPage}/${page}`,
            {
              headers: {
                Authorization: `Bearer ${p_token}`,
              },
            }
          );

          setSolicitations(response.data.data.solicitations);
          setTotalRows(response.data.data.totalAmount);
          setLoading(false);
        } catch (error) {
          toast.error("Acesso não autorizado.");
          handleLogout();
        }
      };
      fetchSolicitatio(1);
    })();
  }, [handleLogout, p_token, perPage]);

  const handleApproved = useCallback(
    async (contract, status) => {
      setLoading(true);
      try {
        await api.post(
          `admin/solicitation/${contract}`,
          { status: status, description: "Solicitação de Benefício Aceita" },
          {
            headers: {
              Authorization: `Bearer ${p_token}`,
            },
          }
        );

        setLoading(false);
        fetchSolicitatios(pagina);
      } catch (error) {
        toast.error("Acesso não autorizado.");
        handleLogout();
      }
    },
    [handleLogout, p_token, fetchSolicitatios, pagina]
  );

  const handleRejected = useCallback(
    async (contract, status) => {
      setLoading(true);
      try {
        await api.post(
          `admin/solicitation/${contract}`,
          { status: status, description: "Solicitação de Recusada" },
          {
            headers: {
              Authorization: `Bearer ${p_token}`,
            },
          }
        );

        setLoading(false);
        fetchSolicitatios(pagina);
      } catch (error) {
        toast.error("Acesso não autorizado.");
        handleLogout();
      }
    },
    [handleLogout, p_token, fetchSolicitatios, pagina]
  );

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        grow: 2,
      },
      {
        name: "Nº Contrato",
        selector: "contractNumber",
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
      },
      {
        name: "WhatsApp",
        selector: "phoneNumber",
        sortable: true,
      },
      {
        name: "Cidade",
        selector: "city",
        sortable: true,
      },
      {
        name: "Ações",
        button: true,
        cell: (row) => (
          <>
            {row.status === "approved" || row.status === "rejected" ? null : (
              <>
                <button
                  // onClick={() => handleApproved(row.contractNumber, "approved")}
                  onClick={(e) =>
                    window.confirm("Deseja aprovar essa solicitação?") &&
                    handleApproved(row.contractNumber, "approved")
                  }
                  type="button"
                  style={{ border: "none", padding: 10 }}
                >
                  <FiUserCheck
                    size={26}
                    style={{
                      verticalAlign: "middle",
                      color: "rgba(63, 195, 128, 0.9)",
                    }}
                  />
                </button>
                <button
                  // onClick={() => handleRejected(row.contractNumber, "rejected")}
                  onClick={(e) =>
                    window.confirm("Deseja recusar essa solicitação?") &&
                    handleRejected(row.contractNumber, "rejected")
                  }
                  type="button"
                  style={{ border: "none", padding: 10 }}
                >
                  <FiUserX
                    size={26}
                    style={{
                      verticalAlign: "middle",
                      color: "rgba(242, 38, 19, 0.9)",
                    }}
                  />
                </button>
              </>
            )}
          </>
        ),
      },
    ],
    [handleApproved, handleRejected]
  );

  const conditionalRowStyles = [
    {
      when: (row) => row.status === "approved",
      style: {
        backgroundColor: "rgba(63, 195, 128, 0.9)",
        color: "#f4f4f4",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    // {
    //   when: (row) => row.status === "inanalysis",
    //   style: {
    //     backgroundColor: "rgba(248, 148, 6, 0.9)",
    //     color: "#f4f4f4",
    //     "&:hover": {
    //       cursor: "pointer",
    //     },
    //   },
    // },
    {
      when: (row) => row.status === "rejected",
      style: {
        backgroundColor: "rgba(242, 38, 19, 0.9)",
        color: "#f4f4f4",
        "&:hover": {
          cursor: "not-allowed",
        },
      },
    },
  ];

  const ExpanableComponent = ({ data }) => (
    <div style={{ padding: 20 }}>
      <p style={{ marginBottom: 10 }}>
        <strong>CPF/CNPJ: </strong> {data.cpfCnpj}
      </p>
      <p style={{ marginBottom: 10 }}>
        <strong>Email: </strong> {data.email}
      </p>
      <p style={{ marginBottom: 10 }}>
        <strong>Justificativa: </strong> {data.description}
      </p>
      <p style={{ marginBottom: 10 }}>
        <strong>Arquivos enviados: </strong> {data.files.length}
        {data.files.length > 0
          ? data.files.map((file, i) => (
              <Link
                key={i}
                to=""
                target="_blank"
                className="button-file"
                onClick={(event) => {
                  event.preventDefault();
                  window.open(file);
                }}
              >
                <FiFile
                  size={26}
                  style={{
                    verticalAlign: "middle",
                  }}
                />
                <span>File {i}</span>
              </Link>
            ))
          : null}
      </p>
    </div>
  );

  const paginationOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  return (
    <div className="dashboard-admin-dashboard">
      <div className="admin-dashboard-container fadeInDown">
        <header>
          <section>
            <div className="topo middle">
              <span className="virtex">Olá, </span>
              <span>
                bem vindo de volta
                <strong> {p_name}</strong>
              </span>
            </div>
          </section>
          <div className="menu">
            <div className="menu-container">
              <div className="middle">
                <div className="menu-miolo">
                  <div className="logo">
                    <img title="Virtex" alt="Virtex" src={logoImg}></img>
                  </div>
                  <div className="register">
                    <button
                      className="button"
                      onClick={handleLogout}
                      type="button"
                    >
                      <FiLogOut
                        size={18}
                        style={{
                          verticalAlign: "middle",
                        }}
                      />
                      <span style={{ paddingLeft: 5 }}>Sair</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="content">
          <div className="middle">
            <div className="content-4">
              <div className="components">
                <main>
                  <section className="title">
                    <h1>Lista de solicitações</h1>
                  </section>
                  <section className="conteudo">
                    <DataTable
                      progressComponent={
                        <PacmanLoader
                          css={`
                            margin: 50px;
                          `}
                        />
                      }
                      paginationComponentOptions={paginationOptions}
                      columns={columns}
                      data={solicitations}
                      progressPending={loading}
                      // highlightOnHover
                      // striped={true}
                      noHeader={true}
                      expandableRows
                      expandableRowsComponent={<ExpanableComponent />}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      conditionalRowStyles={conditionalRowStyles}
                    />
                  </section>
                </main>
              </div>
            </div>
            <div className="footer-admin-dashboard">
              Copyright &copy; 2020 <strong>Virtex</strong>. Todos direitos
              reservados.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
