import React from "react";
import { Link } from "react-router-dom";
import { FiLock } from "react-icons/fi";

import "./styles.css";

import logoImg from "../../assets/virtex3.png";

export default function Home() {
  return (
    <div className="dashboard-home">
      <div className="home-container fadeInDown">
        <header>
          {/* <section>
            <div className="topo middle">
              <span className="VirteX">VirteX. </span>
              <span>
                Nós estamos aqui para não deixar você desconectado do mundo.
                <strong> #FiqueEmCasa</strong>
              </span>
            </div>
          </section> */}
          <div className="menu">
            <div className="menu-container">
              <div className="middle">
                <div className="menu-miolo">
                  <div className="logo">
                    <img title="VirteX" alt="VirteX" src={logoImg}></img>
                  </div>
                  {/* <nav>
                    <ul>
                      <li>
                        <a href="/stuff">O QUE É?</a>
                      </li>
                      <li>
                        <a href="/contact">QUEM PODERÁ RECEBER?</a>
                      </li>
                    </ul>
                  </nav> */}
                  <div className="register">
                    <Link className="button voltarg" to="/login">
                      <FiLock
                        size={18}
                        style={{
                          verticalAlign: "middle",
                        }}
                      />
                      <span style={{ paddingLeft: 5 }}>Solicitar</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="content">
          <div className="middle">
            <div className="content-4">
              <section className="title">
                <h1>NOSSA CONEXÃO É MAIS FORTE</h1>
              </section>
              <div className="components">
                <main>
                  <section className="conteudo">
                    <div className="box">
                      <div className="box-conteudo">
                        <p className="recuo">
                          Atuando sempre com as boas práticas, assumindo mais
                          uma vez nossos princípios de responsabilidade social e
                          empresarial perante a sociedade, a VirteX está
                          acompanhando o aumento de casos confirmados da doença
                          covid-19 e a dificuldade econômica que estamos
                          enfrentando em toda região que atuamos.
                        </p>
                        {/* <p className="recuo">
                          Deste modo, tomamos a iniciativa e lançamos o programa
                        </p> */}
                        <p style={{ textAlign: "center" }}>
                          Deste modo, tomamos a iniciativa e lançamos o programa{" "}
                          <br />
                          <strong> “NOSSA CONEXÃO É MAIS FORTE”.</strong>
                        </p>
                        <p className="recuo">
                          Através de um método simples de análise, iremos gerar
                          benefícios e facilidades para nossos clientes enquanto
                          lutamos juntos para superar esse momento de
                          dificuldade.
                        </p>
                      </div>
                    </div>
                    <div className="box">
                      <span className="box-title">O QUE É?</span>
                      <div className="box-conteudo">
                        <p className="recuo">
                          Programa de auxílio que visa a manutenção da conexão
                          de internet dos clientes VirteX que foram
                          comprovadamente afetados economicamente neste período
                          da pandemia.
                        </p>
                        <p style={{ fontSize: 12 }}>
                          Obs.: Esta é uma iniciativa da VirteX em solidariedade
                          com esta situação gerada pela pandemia, não possuindo
                          nenhum apoio ou vínculo com governo federal, estadual
                          ou municipal.
                        </p>
                      </div>
                    </div>
                    <div className="box">
                      <span className="box-title">QUEM PODERÁ RECEBER?</span>
                      <div className="box-conteudo">
                        <p>
                          <strong>Cliente Residencial</strong>
                        </p>
                        <p className="recuo">
                          Direcionado para clientes, pessoa física, que tiveram
                          uma redução significativa em sua renda mensal, e/ou,
                          tenha ocorrido sua demissão sem justa causa, mediante
                          comprovação com sua carteira de trabalho ou documento
                          formal de rescisão de contrato de trabalho, rescisão
                          de contrato de prestação serviço, etc.
                        </p>
                        {/* <ul>
                          <li>
                            Direcionado para clientes, pessoa física, que
                            tiveram uma redução significativa em sua renda
                            mensal, e/ou, tenha ocorrido sua demissão sem justa
                            causa, mediante comprovação com sua carteira de
                            trabalho ou documento formal de rescisão de contrato
                            de trabalho, rescisão de contrato de prestação
                            serviço, etc.
                          </li>
                        </ul> */}
                        <p>
                          <strong>Cliente Empresarial</strong>
                        </p>
                        <p className="recuo">
                          Direcionado para clientes, pessoa jurídica, que estão
                          em recesso ou precisaram trabalhar de forma reduzida,
                          ou ainda, apenas manter a internet devido ao sistema
                          de monitoramento.
                        </p>
                        {/* <ul>
                          <li>
                            Direcionado para clientes, pessoa jurídica, que
                            estão em recesso ou precisaram trabalhar de forma
                            reduzida, ou ainda, apenas manter a internet devido
                            ao sistema de monitoramento.
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    <div className="box">
                      <span className="box-title">COMO SERÁ O BENEFÍCIO?</span>
                      <div className="box-conteudo">
                        <p>
                          <strong>CLIENTE ACIMA de 5 ANOS</strong>
                        </p>
                        <ul>
                          <li>
                            Permanência do plano de internet e parcelamento de
                            até 5 faturas em até 10x no cartão.
                          </li>
                          <li>
                            Alteração para plano de Internet Emergencial 5 Megas
                            e parcelamento de até 5 faturas em 6x no boleto.
                          </li>
                        </ul>
                        <p>
                          <strong>CLIENTE de 2 a 5 ANOS</strong>
                        </p>
                        <ul>
                          <li>
                            Permanência do plano de internet e parcelamento de
                            até 3 faturas em até 10x no cartão.
                          </li>
                          <li>
                            Alteração para plano de Internet Emergencial 5 Megas
                            e parcelamento de até 3 faturas em 6x no boleto.
                          </li>
                        </ul>
                        <p>
                          <strong>CLIENTE de 6 MESES a 2 ANOS</strong>
                        </p>
                        <ul>
                          <li>
                            Permanência do plano de internet e parcelamento de
                            até 2 faturas em até 6x no cartão.
                          </li>
                          <li>
                            Alteração para plano de Internet Emergencial 5 Megas
                            e parcelamento de até 2 faturas em 3x no boleto.
                          </li>
                        </ul>
                        <p>
                          <strong>
                            A VirteX fará uma análise da conexão do cliente e
                            caso seja constatado que teve redução superior a 75%
                            no “consumo médio” mensal de sua internet, além do
                            benefício serão contemplados com 30% de desconto do
                            valor.
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div className="box">
                      <span className="box-title">
                        COMO RECEBER O BENEFÍCIO?
                      </span>
                      <div className="box-conteudo">
                        <p className="recuo">
                          Este benefício será atendido somente online através do
                          site. Para receber o cliente deve seguir os passos
                          abaixo:
                        </p>
                        <ul>
                          <li>
                            Preencher a ficha do programa{" "}
                            <strong>NOSSA CONEXÃO É MAIS FORTE</strong> no site
                            da VirteX;
                          </li>
                          <li>
                            Anexar foto de documento pessoal do titular da
                            assinatura ou representante legal;
                          </li>
                          <li>Aguardar contato de um consultor da VirteX.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="box">
                      <span className="box-title">Dúvidas?</span>
                      <div className="box-conteudo">
                        <p>
                          Pode ligar ou mandar um whatsapp para o nosso
                          atendimento 0800 591 0350 de segunda a sexta, em
                          horário comercial.
                        </p>
                      </div>
                    </div>
                  </section>
                </main>
              </div>
              <div className="actions">
                <main>
                  <section className="action">
                    <ul>
                      <li>
                        <h3 className="titulo">
                          Confirme se o seu processo do "NOSSA CONEXÃO É MAIS
                          FORTE" já está pronto
                        </h3>
                        <div className="conteudo">
                          <Link className="button voltarg" to="/login">
                            <span style={{ paddingLeft: 5 }}>CLIQUE AQUI</span>
                          </Link>
                        </div>
                        <div className="footer">
                          E veja se seu auxílio já está disponível.
                        </div>
                      </li>
                    </ul>
                  </section>
                </main>
              </div>
            </div>
            <div className="footer-home">
              Copyright &copy; 2020 <strong>VirteX</strong>. Todos direitos
              reservados.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
