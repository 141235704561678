import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { FiHome, FiLogIn } from "react-icons/fi";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";

import api from "../../services/api";

import "./styles.css";

import logoImg from "../../assets/virtex3.png";

export default function AdminLogin() {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);

  toast.configure({
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });

  const history = useHistory();

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  async function handleLogin(e) {
    e.preventDefault();

    setLoading(true);

    try {
      if (!pass || !user) {
        await sleep(3000);
        setLoading(false);
        toast.warning("Por favor preencha os dados e tente novamente.");
        setPass("");
        setUser("");
        return;
      }

      const response = await api.post("/admin/authenticate", {
        username: user,
        password: pass,
      });

      if (response.data.success) {
        await sleep(3000);
        setLoading(false);

        toast.success("Dados obtidos com sucesso");

        localStorage.setItem("VP_NAME", response.data.data.user);
        localStorage.setItem("VP_TOKEN", response.data.data.token);

        history.push("/dashboard");
      } else {
        await sleep(3000);
        setLoading(false);
        toast.error(
          "Não foi possível obter seus dados. por favor tente novamente."
        );
      }
    } catch (error) {
      await sleep(3000);
      setLoading(false);
      toast.error(
        "Não foi possível obter seus dados. por favor tente novamente."
      );
      setPass("");
      setUser("");
      return;
    }
  }

  return (
    <div className="dashboard-admin-login">
      <div className="admin-login-container fadeInDown">
        <header>
          {/* <section>
            <div className="topo middle">
              <span className="virtex">Virtex. </span>
              <span>
                Nós estamos aqui para não deixar você desconectado do mundo.
                <strong> #FiqueEmCasa</strong>
              </span>
            </div>
          </section> */}
          <div className="menu">
            <div className="menu-container">
              <div className="middle">
                <div className="menu-miolo">
                  <div className="logo">
                    <img title="Virtex" alt="Virtex" src={logoImg}></img>
                  </div>
                  {/* <nav>
                    <ul>
                      <li>
                        <a href="/profile">VISUALIZAR ASSINATURAS</a>
                      </li>
                    </ul>
                  </nav> */}
                  <div className="register">
                    <Link className="button voltarg" to="/">
                      <FiHome
                        size={18}
                        style={{
                          verticalAlign: "middle",
                        }}
                      />
                      <span style={{ paddingLeft: 5 }}> Página Inicial</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="wrapper fadeInDown">
          <div id="formContent">
            <form onSubmit={handleLogin} className="fadeIn second">
              {/* <h1>Faça seu login:</h1> */}

              <div className="box-form-group">
                <label>Usuário:</label>
                <input
                  required
                  type="text"
                  name="user"
                  className="form-elemento"
                  autoComplete="off"
                  placeholder="Digite seu Usuário"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                ></input>
              </div>
              <div className="box-form-group">
                <label>Senha:</label>
                <input
                  required
                  type="password"
                  name="pass"
                  className="form-elemento"
                  autoComplete="off"
                  placeholder="Digite sua Senha"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                ></input>
              </div>

              {loading ? (
                <button
                  className="button fadeIn third"
                  disabled
                  style={{ marginTop: 15 }}
                >
                  <HashLoader
                    size={36}
                    color={"#fff"}
                    css={`
                      display: block;
                      margin: 0 auto;
                    `}
                  />
                </button>
              ) : (
                <button
                  className="button fadeIn third"
                  type="submit"
                  style={{ marginTop: 15 }}
                >
                  <FiLogIn
                    size={18}
                    style={{
                      verticalAlign: "middle",
                    }}
                  />
                  <span style={{ paddingLeft: 5 }}>Entrar</span>
                </button>
              )}
            </form>
          </div>
        </div>

        <div className="footer-admin-login">
          Copyright &copy; 2020 <strong>Virtex</strong>. Todos direitos
          reservados. <br /> CNPJ 05.439.562/0001-50
        </div>
      </div>
    </div>
  );
}
